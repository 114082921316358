/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from 'react';

const Header = ({ loggedIn }) => {
  useEffect(() => {
    console.log({ loggedIn });
  }, [loggedIn]);
  return (
    <header className="aui-main-header aui-pri-header">
      <a href="www.heart.org" className="aui-skip-content">
        Skip to main content
      </a>
      <nav className="navbar navbar-expand-lg justify-content-between aui-header-content mx-auto aui-pri-header-t">
        <a
          href="www.heart.org"
          className="aha-logo"
          aria-label="AHA Chatbot Frameork logo"
        ></a>

        <button
          className="navbar-toggler ml-2 px-0"
          type="button"
          data-toggle="collapse"
          data-target="#toggleNav"
          aria-controls="toggleNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="aha-icon-hamburger"></i>
        </button>
        <div
          className="justify-content-lg-end collapse navbar-collapse aui-pri-nav"
          id="toggleNav"
        >
          <button
            type="button"
            className="btn btn-primary btn-round"
            data-toggle="modal"
            data-target="#signinmodal"
          >
            {!loggedIn ? 'Sign In' : 'Sign Out'}
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
