import React, { useState, useEffect } from 'react';

const SignInComponent = ({ loggedIn, setLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const userCredentials = JSON.parse(localStorage.getItem('userCredentials'));
    if (userCredentials && userCredentials.email && userCredentials.password) {
      setLoggedIn(true);
    }
  }, []);

  const getEmail = () => {
    const userCredentials = JSON.parse(localStorage.getItem('userCredentials'));
    return userCredentials.email;
  };

  const handleSignIn = e => {
    e.preventDefault();
    // Here you can add your sign-in logic
    // For demonstration purposes, let's assume successful sign-in
    const userCredentials = {
      email,
      password,
    };
    localStorage.setItem('userCredentials', JSON.stringify(userCredentials));
    setLoggedIn(true);
    console.log('Sign-in successful');
  };
  const handleSignOut = () => {
    localStorage.removeItem('userCredentials');
    setLoggedIn(false);
    setEmail('');
    setPassword('');
    console.log('Sign-out successful');
  };

  return (
    <div
      className="modal fade show aui-org-modal aui-new-org aui-modal"
      id="signinmodal"
      tabIndex="-1"
      aria-labelledby="modalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="aui-quik-close d-flex justify-content-end">
            <button
              type="button"
              className="close aha-modal-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card">
            <div className="card-body">
              {loggedIn ? (
                <>
                  <p className="text-success">
                    You are already signed in. <b>{getEmail()}</b>
                  </p>
                  <button className="btn btn-danger" onClick={handleSignOut}>
                    Sign Out
                  </button>
                </>
              ) : (
                <form onSubmit={handleSignIn}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInComponent;
