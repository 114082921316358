import React, { useEffect, useState } from 'react';
import SignInComponent from './Login';

import './App.css';
import Header from './Header';

const App = () => {
  const [config, setConfig] = useState({
    serviceUrl: 'https://chatbot-dev-api.heartblr.org',
  });
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <div className="App container text-left">
      <Header loggedIn={loggedIn} />

      {/* <button onClick={()=>}>Sign In</button> */}
      <SignInComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      <div className="container">
        <h2>Introduction</h2>
        <p>
          In this tutorial, we will explore the process of integrating a
          chat-bot into a React project using the provided SDK. Chat-bots have
          become an essential part of modern web applications, enhancing user
          interaction and engagement.
        </p>

        <h2>Getting Started</h2>
        <p>To get started with chat-bot integration, follow these steps:</p>
        <ol>
          <li>Install the chat-bot SDK package using npm or yarn.</li>
          <li>Import the necessary components and utilities from the SDK.</li>
          <li>Initialize the chat-bot with your API key and configuration.</li>
          <li>Render the chat interface within your React application.</li>
        </ol>

        <h2>Implementation Example</h2>
        <p>
          Here's a simple example of how to integrate the chat-bot into a React
          component:
        </p>
        <pre>
          <code>
            {`
              import React, { useEffect } from 'react';
                import { ChatBot } from 'chat-bot-sdk';

                function ChatBotComponent() {
                  useEffect(() => {
                    const chatBot = new ChatBot('YOUR_API_KEY', {
                      theme: 'light',
                      // Other configuration options...
                    });
                    chatBot.init();
                  }, []);

                  return (
                    &lt;div id="chat-bot-container"&gt;&lt;/div&gt;
                  );
                }

                export default ChatBotComponent;`}
          </code>
        </pre>

        <h2>Conclusion</h2>
        <p>
          Integrating a chat-bot using the provided SDK is a straightforward
          process that can greatly enhance user experience in your React
          application. By following this guide, you'll be able to create a
          seamless and interactive chat-bot integration.
        </p>
      </div>
      <chat-bot id="chat-bot" config={JSON.stringify(config)}></chat-bot>
      <div style={{ height: '94vh' }}></div>
    </div>
  );
};

export default App;
